<template>
  <VeeForm
    ref="formRef"
    @invalid-submit="onInvalidSubmit"
    @submit="onSubmit"
  >
    <slot />
  </VeeForm>
</template>

<script lang="ts" setup>
import { Form as VeeForm } from 'vee-validate';
import { ref } from 'vue';

const emit = defineEmits(['before-submit', 'submit', 'invalid-submit']);
const formRef = ref();

function onInvalidSubmit(...args: any[]) {
  emit('invalid-submit', ...args);
}
function onSubmit(...args: any[]) {
  emit('before-submit');
  emit('submit', ...args);
}
function validate() {
  return formRef.value?.validate();
}
defineExpose({ validate });
</script>
